<template>
     <AppBanner>
        <template #main-heading>
            <h2 class="text-capitalize text-white ">YOUR HOME THE WAY YOU'VE ALWAYS WANTED IT.</h2>
        </template>
        <h5 class="text-white">When you live here, you get more than a home.
            You get an entire neighborhood where you can live, work, shop, dine, and play.</h5>
        <p class="text-white">
            Our homes include an abundance of neighborhood retail, restaurants, office space, and more.
            With so much convenience right at your doorstep, there's hardly a need to venture further, but the direct access
            to the Metro, I-495, and I-395 make downtown DC, Old Town Alexandria, and more easily accessible.
        </p>
    </AppBanner>
    <!-- Form contact -->
    <section class="wrap__contact-form">
        <div class="container" style="min-height: 40vh;">
            <div class="row" style="max-width: 800px; margin: auto;">
                <!-- <div class="col-md-8">
                    <h5>contact us</h5>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group form-group-name">
                                <label>First Name <span class="required"></span></label>
                                <input type="text" v-model="contact.first_name" class="form-control" name="name" required="">

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group form-group-name">
                                <label>Last Name <span class="required"></span></label>
                                <input type="text" v-model="contact.last_name" class="form-control" name="last_name" required="">

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group form-group-name">
                                <label>Email <span class="required"></span></label>
                                <input type="email" v-model="contact.email" class="form-control" name="email" required="">

                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group form-group-name">
                                <label>Phone <span class="required"></span></label>
                                <input type="text" class="form-control" v-model="contact.phone" name="phone" required="">

                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group form-group-name">
                                <label>Subject <span class="required"></span></label>
                                <input type="text" class="form-control" v-model="contact.subject" name="subject" required="">

                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>Your Message </label>
                                <textarea v-model="contact.bodyContent" class="form-control" rows="9" name="message"></textarea>
                            </div>
                            <div class="form-group float-right mb-0">
                                <button type="button" @click="contactUs" class="btn btn-success btn-contact">Submit</button>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-6">
                    <h5>Contact Information</h5>
                    <div class="wrap__contact-form-office">
                        <ul class="list-unstyled">
                            <li>
                                <span>
                                    <i class="fa fa-envelope"></i>
                                </span>

                                PO Box 4146 Merrifield, VA 22116


                            </li>
                            <li>
                                <span>
                                    <i class="fa fa-phone"></i>
                                    <a href="tel:">(703) 677-5944</a>
                                </span>

                            </li>
                            <!-- <li>
                                <span>
                                    <i class="fa fa-envelope"></i>
                                    <a href="mailto:safeandsoundrealestate@gmail.com">safeandsoundrealestate@gmail.com</a>
                                </span>

                            </li> -->
                        </ul>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="wrap__contact-open-hours">
                        <h5 class="text-capitalize">Business Hours</h5>
                        <ul class="list-unstyled">
                            <li class="d-flex align-items-center justify-content-between"><span>Monday - Sunday</span>
                                <span>9 AM - 5 PM</span></li>
                        </ul>
                    </div>

                </div>
            


            </div>
        </div>
    </section>
    <!-- End Form contact  -->
</template>

<script>
import toast from '@/store/toast';
import AppBanner from '@/layouts/AppBanner.vue';

    export default {
        components: {
        AppBanner,
    },
        data() {
            return {
                contact: {
                        first_name: "",
                        last_name: "",
                        email: "",
                        phone: "",
                        subject: "",
                        bodyContent: "",
                    }
            }
        },
        methods:{
            contactUs() {
                const obj = this.contact;
                let hasValues = false;
                for (const key in obj) {
                // eslint-disable-next-line no-prototype-builtins
                if (obj.hasOwnProperty(key) && obj[key] !== '') {
                    hasValues = true;
                    break;
                }
            }
                if(hasValues === false) return toast('Please complete all fields', 'error')
                this.$store.dispatch('contact/contactUs', this.contact).then(() => {
                    this.contact =  {
                        first_name: "",
                        last_name: "",
                        email: "",
                        phone: "",
                        subject: "",
                        bodyContent: "",
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>