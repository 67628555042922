<template>
    <!-- LISTING LIST -->
    <section>
        <div class="container">
            <div class="row">
             <PropertyList />
   
            </div>
        </div>
    </section>
    <!-- END LISTING LIST -->
</template>

<script>
import PropertyList from '@/components/PropertyList.vue';

    export default {
    components: { PropertyList }
}
</script>

<style lang="scss" scoped>

</style>