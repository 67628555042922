<template>
<!-- SINGLE DETAIL -->
<AppBanner>
        <h2 class="text-capitalize text-white ">YOUR HOME THE WAY YOU'VE ALWAYS WANTED IT.</h2>
        <h5 class="text-white">When you live here, you get more than a home.
            You get an entire neighborhood where you can live, work, shop, dine, and play.</h5>
        <p class="text-white">
            Our homes include an abundance of neighborhood retail, restaurants, office space, and more.
            With so much convenience right at your doorstep, there's hardly a need to venture further, but the direct access
            to the Metro, I-495, and I-395 make downtown DC, Old Town Alexandria, and more easily accessible.
        </p>
    </AppBanner>
<section class="single__Detail">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <!-- TITLE PROPERTY AND PRICE  -->
                    <div class="single__detail-area pt-0 pb-4">
                        <div class="row">
                            <div class="col-md-8 col-lg-8">
                                <div class="single__detail-area-title">
                                    <h3 class="text-capitalize">{{ property.property_name}}</h3>
                                    <p> {{ property.street_address}} <br/>{{ property.city }} {{ property.state }} {{ property.zip_code }}</p>
                                </div>
                            </div>
                            <div class="col-md-4 col-lg-4">
                                <div class="single__detail-area-price">
                                    <h3 class="text-capitalize text-gray">{{ formatCurrency(property.monthly_rent_wanted)  }} /Month</h3>
                                    <!-- <ul class="list-inline">
                                        <li class="list-inline-item">
                                            <a href="#" class="badge badge-primary p-2 rounded"><i
                                                    class="fa fa-exchange"></i></a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a href="#" class="badge badge-primary p-2 rounded"><i
                                                    class="fa fa-heart"></i></a>
                                        </li>
                                        <li class="list-inline-item">
                                            <a href="#" class="badge badge-primary p-2 rounded"><i
                                                    class="fa fa-print"></i></a>
                                        </li>
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END TITLE PROPERTY AND PRICE  -->

                    <!-- SLIDER IMAGE DETAIL -->
                    <!-- <div class="slider__image__detail-large-two owl-carousel owl-theme">
                        <div class="item" v-for="(images, index) in property.property_gallery" :key="index">
                            <div class="slider__image__detail-large-one">
                                <img :src="getImage(images.file_name)" alt="" class="img-fluid w-100 img-transition">

                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="slider__image__detail-thumb-two owl-carousel owl-theme">
                        <div class="item" v-for="(images, index) in property.property_gallery" :key="index">
                            <div class="slider__image__detail-thumb-one">
                                <img :src="getImage(images.file_name)" alt="" class="img-fluid w-100 img-transition">
                            </div>
                        </div>
                    </div> -->
                    <!-- END SLIDER IMAGE DETAIL -->

                    <div class="slick-slider mb-5">
        <div class="main-slider-for site_gallery_slider" >
          <div class="slide" v-for="(images, index) in gallery" :key="index">
            <img :data-lazy="getImage(images.file_name)" alt="" class="img-fluid w-100 img-transition" />
        </div>
     
          <!-- Add more slides as needed -->
        </div>

        <div class="slider-nav site_gallery_thumb">
            <div class="slide" v-for="(images, index) in gallery" :key="index">
            <img :data-lazy="getImage(images.file_name)" alt="" class="img-fluid w-100 img-transition" />
        </div>
          <!-- Add more slides as needed -->
        </div>
      </div>
                </div>
                <div class="col-lg-4 pt-5">
                    <!-- FORM FILTER -->
                    <div class="products__filter mb-30">
                        <!-- <div class="products__filter__group">
                            <div class="products__filter__header">

                                <h5 class="text-center text-capitalize">property filter</h5>
                            </div>
                            <div class="products__filter__body">
                                <div class="form-group">

                                    <select class="wide select_option">
                                        <option data-display="Property Status">Property Status</option>
                                        <option>For Sale</option>
                                        <option>For Rent</option>

                                    </select>
                                </div>
                                <div class="form-group">
                                    <select class="wide select_option">
                                        <option data-display="Property Type">Property Type</option>
                                        <option>Residential</option>
                                        <option>Commercial</option>
                                        <option>Land</option>
                                        <option>Luxury</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <select class="wide select_option">
                                        <option data-display="Area From">Area From </option>
                                        <option>1500</option>
                                        <option>1200</option>
                                        <option>900</option>
                                        <option>600</option>
                                        <option>300</option>
                                        <option>100</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <select class="wide select_option">
                                        <option data-display="Locations">Locations</option>
                                        <option>United Kingdom</option>
                                        <option>American Samoa</option>
                                        <option>Belgium</option>
                                        <option>Canada</option>
                                        <option>Delaware</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <select class="wide select_option">
                                        <option data-display="Bedrooms">Bedrooms</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>
                                        <option>6</option>
                                        <option>7</option>
                                        <option>8</option>
                                        <option>9</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <div class="form-group">
                                        <select class="wide select_option">
                                            <option data-display="Bathrooms">Bathrooms</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>

                                        </select>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="mb-3">Price range</label>
                                    <div class="filter__price">
                                        <input class="price-range" type="text" name="my_range" value="" />
                                    </div>
                                </div>

                                <div class="form-group mb-0 mt-2">

                                    <a class="btn btn-outline-primary btn-block text-capitalize advanced-filter"
                                        data-toggle="collapse" href="#multiCollapseExample1"
                                        aria-controls="multiCollapseExample1"><i class="fa fa-plus-circle"></i> advanced
                                        filter
                                    </a>

                                    <div class="collapse multi-collapse" id="multiCollapseExample1">
                                        <div class="advancedfilter">
                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox2" type="checkbox">
                                                <label for="checkbox2" class="label-brand text-capitalize">
                                                    Air Conditioning
                                                </label>

                                            </div>
                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox3" type="checkbox">
                                                <label for="checkbox3" class="label-brand text-capitalize">
                                                    Swiming Pool
                                                </label>

                                            </div>
                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox4" type="checkbox">
                                                <label for="checkbox4" class="label-brand text-capitalize">
                                                    Central Heating
                                                </label>

                                            </div>
                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox5" type="checkbox">
                                                <label for="checkbox5" class="label-brand text-capitalize">
                                                    Spa & Massage
                                                </label>

                                            </div>
                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox6" type="checkbox">
                                                <label for="checkbox6" class="label-brand text-capitalize">
                                                    Pets Allow
                                                </label>

                                            </div>
                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox7" type="checkbox">
                                                <label for="checkbox7" class="label-brand text-capitalize">
                                                    Air Conditioning
                                                </label>

                                            </div>

                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox8" type="checkbox">
                                                <label for="checkbox8" class="label-brand text-capitalize">
                                                    Gym
                                                </label>

                                            </div>

                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox9" type="checkbox">
                                                <label for="checkbox9" class="label-brand text-capitalize">
                                                    Alarm
                                                </label>

                                            </div>
                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox10" type="checkbox">
                                                <label for="checkbox10" class="label-brand text-capitalize">
                                                    Window Covering
                                                </label>

                                            </div>
                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox11" type="checkbox">
                                                <label for="checkbox11" class="label-brand text-capitalize">
                                                    Free WiFi
                                                </label>

                                            </div>
                                            <div class="checkbox checkbox-primary">
                                                <input id="checkbox12" type="checkbox">
                                                <label for="checkbox12" class="label-brand text-capitalize">
                                                    Car Parking
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="products__filter__footer">
                                <div class="form-group mb-0">
                                    <button class="btn btn-primary text-capitalize btn-block"><i
                                            class="fa fa-search ml-1"></i> search
                                        property </button>

                                </div>
                            </div>
                        </div> -->

                    </div>
                    <!-- END FORM FILTER -->
                    <!-- FORM FILTER -->
                    <!-- <div class="products__filter mb-30">
                        <div class="products__filter__group">
                            <div class="products__filter__header">

                                <h5 class="text-center text-capitalize">simulation calculator </h5>
                            </div>
                            <div class="products__filter__body">
                                <div class="form-group">
                                    <label>Sale Price</label>

                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>

                                        </div>
                                        <input type="text" class="form-control" placeholder="$130.000">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Down Payment</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">$</span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="$6.000">
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Long Term (Years)</label>
                                    <select class="select_option wide">

                                        <option value="1">10</option>
                                        <option value="2">15</option>
                                        <option value="3">20</option>
                                        <option value="4">25</option>
                                    </select>

                                </div>

                                <div class="form-group">
                                    <label>Interest Rate</label>

                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">%</span>

                                        </div>
                                        <input type="text" class="form-control" placeholder="10%">
                                    </div>
                                </div>
                            </div>
                            <div class="products__filter__footer">
                                <div class="form-group mb-0">
                                    <button class="btn btn-primary text-capitalize btn-block"> calculate
                                        <i class="fa fa-calculator ml-1"></i>
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!-- END FORM FILTER -->
                    <div class="sticky-top">
                        <!-- PROFILE AGENT -->
                        <!-- <div class="profile__agent mb-30">
                            <div class="profile__agent__group">

                                <div class="profile__agent__header">
                                    <div class="profile__agent__header-avatar">
                                        <figure>
                                            <img src="../assets/images/amber-williams-17416828.jpg" alt="" class="img-fluid">
                                        </figure>

                                        <ul class="list-unstyled mb-0">
                                            <li>
                                                <h5 class="text-capitalize">Amber Williams</h5>
                                            </li>
                                            <li><a href="tel:123456"><i
                                                        class="fa fa-phone-square mr-1"></i>703-677-5944</a></li>
                                            <li><a href="javascript:void(0)"><i class=" fa fa-building mr-1"></i>
                                                    Manager</a>
                                            </li>
                                           
                                        </ul>


                                    </div>

                                </div>
                            </div>

                        </div> -->
                        <!-- END PROFILE AGENT -->
                        <div class="download mb-0">
                            <h5 class=" text-capitalize">Ready? Complete the Rental Application</h5>
                            <div class="download__item">
                                <a :href="downloadLink()" target="_blank"><i class="fa fa-file-pdf-o mr-3"
                                        aria-hidden="true"></i>Download Rental Application</a>
                            </div>
                        </div>


                    </div>
                </div>
                <div class="col-lg-8">
                    <!-- DESCRIPTION -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="single__detail-desc mb-5">
                                <h4 class="text-capitalize detail-heading">description</h4>
                                <p v-html="property.description"></p>
                                <!-- <div class="show__more">
                                    <p v-html="property.description"></p>
                                
                                    <a href="javascript:void(0)" class="show__more-button ">read more</a>
                                </div> -->
                            </div>
                            <div class="clearfix"></div>

                            <!-- PROPERTY DETAILS SPEC -->
                            <div class="single__detail-features">
                                <h4 class="text-capitalize detail-heading">property details</h4>
                                <!-- INFO PROPERTY DETAIL -->
                                <div class="property__detail-info">
                                    <div class="row">
                                        <div class="col-md-6 col-lg-6">
                                            <ul class="property__detail-info-list list-unstyled">
                                                <li><b>Property Name:</b> {{ property.property_name }}</li>
                                                <li><b>Price:</b> {{ formatCurrency(property.monthly_rent_wanted) }}</li>
                                                <li><b>Size:</b> {{ property.size }} Sq Ft</li>
                                                <li><b>Bedroom:</b> {{ property.bedroom == 0 ? 'Studio' : property.bedroom }}</li>
                                                <li><b>Bathroom:</b>{{ property.bath_half == 0 ? property.bath_full : `${property.bath_full} Full / ${property.bath_half} Half` }}</li>
                                                <li><b>Property Type:</b> <span >{{ property.property_type }}</span></li>
                                            </ul>
                                        </div>
                                        <div class="col-md-6 col-lg-6">
                                            <ul class="property__detail-info-list list-unstyled">
                                                <!-- <li class="text-capitalize"><b class="capitalize">Status:</b> {{property.status}}</li> -->
                                                <li class="text-capitalize"><b>Parking Space:</b> {{property.parking_spaces === "no" ? 'Ask about parking options' : property.parking_spaces}}</li>
                                                <!-- <li v-if="property.parking_spaces !== 'no'"><b>Parking Fees:</b> {{formatCurrency(property.parking_fees)}}</li> -->
                                                <!-- <li v-if="property.parking_spaces !== 'no'"><b>Parking Number:</b> {{property.parking_number}}</li> -->
                                                <li class="text-capitalize"><b>Furnished:</b> {{property.furnished}}</li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                                <!-- END INFO PROPERTY DETAIL -->
                            </div>
                            <!-- END PROPERTY DETAILS SPEC -->

                                   <!-- PROPERTY DETAILS SPEC -->
                                   <div class="single__detail-features">
                                <h4 class="text-capitalize detail-heading">Availability</h4>
                                <!-- INFO PROPERTY DETAIL -->
                                <div class="property__detail-info">
                                    <div class="row">
                                        <div class="col-md-6 col-lg-6">
                                            <ul class="property__detail-info-list list-unstyled">
                                                <li><b>When Available:</b> {{ handleCheckNowDate(property.available_property_date) }} </li>
                                                <!-- <li><b>Lease Start Date:</b> {{ property.lease_start_date }}</li>
                                                <li><b>Lease End Date:</b> {{ property.lease_end_date }}</li> -->
                                                
                                                <!-- <li><b>Bedrooms:</b> {{ property.bedroom }}</li>
                                                <li><b>Bathrooms:</b> {{property.bathroom}}</li>
                                                <li><b>Property Type:</b> <span >{{ property.property_type }}</span></li> -->
                                            </ul>
                                        </div>
                                        <!-- <div class="col-md-6 col-lg-6">
                                            <ul class="property__detail-info-list list-unstyled">
                                                <li><b>Property Status:</b> {{property.status}}</li>
                                                <li><b>Property Parking Space:</b> {{property.parking_spaces}}</li>
                                                <li><b>Property Parking Fees:</b> {{property.parking_fees}}</li>
                                                <li><b>Furnished:</b> {{property.furnished}}</li>
                                            </ul>
                                        </div> -->
                                    </div>

                                </div>
                                <!-- END INFO PROPERTY DETAIL -->
                            </div>
                            <!-- END PROPERTY DETAILS SPEC -->


                            <div class="clearfix"></div>

                            <!-- FEATURES -->
                            <div class="single__detail-features" v-if="property.amenity && property.amenity.length > 0">
                                <h4 class="text-capitalize detail-heading">Amenities</h4>
                                <ul class="list-unstyled icon-checkbox">
                                    <li v-for="item in property.amenity" :key="item.id">{{item.name}}</li>
                                </ul>
                            </div>
                            <!-- END FEATURES -->

                             <!-- END PROPERTY DETAILS SPEC -->
                             <div class="clearfix"></div>

<!-- FEATURES -->
<div class="single__detail-features" v-if="property.utility_include && property.utility_include.length > 0">
    <h4 class="text-capitalize detail-heading">Utilities Included</h4>
    <ul class="list-unstyled icon-checkbox">
        <li v-for="item in property.utility_include" :key="item.id">{{item.name}}</li>
    </ul>
</div>
<!-- END FEATURES -->


 <!-- END PROPERTY DETAILS SPEC -->
 <div class="clearfix"></div>

<!-- FEATURES -->
<div class="single__detail-features" v-if="property.parking_type && property.parking_type.length > 0">
    <h4 class="text-capitalize detail-heading">Parking Type</h4>
    <ul class="list-unstyled icon-checkbox">
        <li v-for="item in property.parking_type" :key="item.id">{{item.name}}</li>
    </ul>
</div>
<!-- END FEATURES -->
                            <!-- LOCATION -->
                            <div class="single__detail-features">
                                <h4 class="text-capitalize detail-heading">location</h4>
                                 <!-- <div id="mapContainer" style="height: 400px;">
      <l-map :zoom="11" :center="initialLocation">
        <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
        <l-marker :lat-lng="initialLocation"></l-marker>
      </l-map>
    </div> -->
                                <MapView />
                                <!-- FILTER VERTICAL -->
                                <!-- <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="pills-map-location-tab" data-toggle="pill"
                                            href="#pills-map-location" role="tab" aria-controls="pills-map-location"
                                            aria-selected="true">
                                            <i class="fa fa-map-marker"></i>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-street-view-tab" data-toggle="pill"
                                            href="#pills-street-view" role="tab" aria-controls="pills-street-view"
                                            aria-selected="false">
                                            <i class="fa fa-street-view "></i></a>
                                    </li>


                                </ul> -->
                                
                                <!-- <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-map-location" role="tabpanel"
                                        aria-labelledby="pills-map-location-tab">
                                        <div id="map-canvas">
                                            <div id="mapContainer" style="height: 400px;">
      <l-map :zoom="13" :center="initialLocation">
        <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
        <l-marker :lat-lng="initialLocation"></l-marker>
      </l-map>
    </div>
                                        </div>

                                    </div>
           


                                </div> -->
                                <!-- END FILTER VERTICAL -->
                            </div>
                            <!-- END LOCATION -->

                            <!-- PROPERTY VIEWS -->
                            <!-- <div class="single__detail-features">
                                <h6 class="text-capitalize detail-heading">property views</h6>
                              
                                <div class="wrapper">
                                    <canvas id="myChart" class="chart"></canvas>
                                </div>
                            </div> -->
                            <!-- END PROPERTY VIEWS -->

                            <!-- NEARBY -->
                            <!-- <div class="single__detail-features">
                                <h6 class="text-capitalize detail-heading">what's nearby</h6>
                                <div class="single__detail-features-nearby">
                                    <h6 class="text-capitalize"><span>
                                            <i class="fa fa-building "></i></span>education</h6>
                                    <ul class="list-unstyled">
                                        <li>
                                            <span>Eladia's Kids</span>
                                            <p>2.5 km</p>
                                        </li>
                                        <li>
                                            <span>Brooklyn Brainery</span>
                                            <p>3.5 km</p>

                                        </li>
                                        <li>
                                            <span>Wikdom Senior High Scool</span>
                                            <p>2.5 km</p>
                                        </li>

                                    </ul>

                                    <h6 class="text-capitalize"><span><i class="fa fa-ambulance"></i></span>health &
                                        medical
                                    </h6>
                                    <ul class="list-unstyled">
                                        <li>
                                            <span>Eladia's Kids</span>
                                            <p>2.5 km</p>
                                        </li>
                                        <li>
                                            <span>Brooklyn Brainery</span>
                                            <p>3.5 km</p>

                                        </li>
                                        <li>
                                            <span>Wikdom Senior High Scool</span>
                                            <p>2.5 km</p>
                                        </li>
                                    </ul>
                                </div>
                            </div> -->
                            <!-- END NEARBY -->

                            <!-- RATE US  WRITE -->
                            <!-- <div class="single__detail-features">
                                <h6 class="text-capitalize detail-heading">Rate us and Write a Review</h6>
                                <div class="single__detail-features-review">
                                    <div class="media mt-4">
                                        <img class="mr-3 img-fluid rounded-circle" src="images/80x80.jpg" alt="">
                                        <div class="media-body">
                                            <h6 class="mt-0">Jhon doe</h6>
                                            <span class="mb-3">Mei 13, 2020</span>
                                            <ul class="list-inline">
                                                <li class="list-inline-item">
                                                    <i class="fa fa-star selected"></i>
                                                    <i class="fa fa-star selected"></i>
                                                    <i class="fa fa-star selected"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </li>
                                                <li class="list-inline-item">3/5</li>
                                            </ul>
                                            <p> Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque
                                                ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus
                                                viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla.
                                                Donec
                                                lacinia congue felis in faucibus.</p>

                                            <div class="media mt-4">
                                                <a class="pr-3" href="#">
                                                    <img src="images/80x80.jpg" alt="" class="img-fluid rounded-circle">
                                                </a>
                                                <div class="media-body">
                                                    <h6 class="mt-0">Christine </h6>
                                                    <span class="mb-3">Mei 13, 2020</span>
                                                    <ul class="list-inline">
                                                        <li class="list-inline-item">
                                                            <i class="fa fa-star selected"></i>
                                                            <i class="fa fa-star selected"></i>
                                                            <i class="fa fa-star selected"></i>
                                                            <i class="fa fa-star selected"></i>
                                                            <i class="fa fa-star selected"></i>
                                                        </li>
                                                        <li class="list-inline-item">5/5</li>
                                                    </ul>
                                                    <p> Cras sit amet nibh libero, in gravida nulla. Nulla vel metus
                                                        scelerisque ante sollicitudin. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="media mt-4">
                                        <img class="mr-3 img-fluid rounded-circle" src="images/80x80.jpg" alt="">
                                        <div class="media-body">
                                            <h6 class="mt-0">Jhon Doe</h6>
                                            <span class="mb-3">Mei 13, 2020</span>
                                            <ul class="list-inline">
                                                <li class="list-inline-item">
                                                    <i class="fa fa-star selected"></i>
                                                    <i class="fa fa-star selected"></i>
                                                    <i class="fa fa-star selected"></i>
                                                    <i class="fa fa-star selected"></i>
                                                    <i class="fa fa-star selected"></i>
                                                </li>
                                                <li class="list-inline-item">5/5</li>
                                            </ul>
                                            <p> Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque
                                                ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus
                                                viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla.
                                                Donec
                                                lacinia congue felis in faucibus.</p>


                                        </div>
                                    </div>
                                
                                    <hr>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <p class="mb-2">Your rating for this listing:</p>
                                            <ul class="list-inline">
                                                <li class="list-inline-item">
                                                    <i class="fa fa-star selected"></i>
                                                    <i class="fa fa-star selected"></i>
                                                    <i class="fa fa-star selected"></i>
                                                    <i class="fa fa-star"></i>
                                                    <i class="fa fa-star"></i>
                                                </li>
                                                <li class="list-inline-item">3/5</li>
                                            </ul>
                                            <div class="form-group">
                                                <label>Your Name</label>
                                                <input type="text" class="form-control" required="required">

                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>What's your Email?</label>
                                                <input type="email" class="form-control" required="required">

                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Subject</label>
                                                <input type="text" class="form-control" required="required">

                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Your message</label>
                                                <textarea class="form-control" rows="4"></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" class="btn btn-primary float-right "> Submit review <i
                                            class="fa fa-paper-plane ml-2"></i></button>
                                   
                                </div>
                            </div> -->
                            <!-- END RATE US  WRITE -->
                        </div>
                    </div>
                    <!-- END DESCRIPTION -->
                </div>
            </div>

        </div>
    </section>
    <!-- END SINGLE DETAIL -->

</template>

<script>
import $ from 'jquery'; // Import jQuery
import 'slick-carousel/slick/slick.css'; // Import Slick Slider CSS
import 'slick-carousel/slick/slick-theme.css'; // Import Slick Slider Theme CSS
import 'slick-carousel/slick/slick'; // Import Slick Slider JS


import moment from 'moment'
import AppBanner from '@/layouts/AppBanner.vue';
import {formatCurrencyMixin} from '../mixins/formatCurrencyMixin'
import MapView from '../components/MapView.vue'
import 'leaflet/dist/leaflet.css';
export default {
    components: {
        MapView,
        AppBanner,
    },
    mixins: [formatCurrencyMixin],
    data() {
    return {
        property: {

        },
        gallery: [],
    };
  },
    mounted() {
        this.fetchPropertyData()
        // this.initialLocation = [this.property.latitude, this.property.longitude]
    },
    methods: {
        downloadLink(){
        return process.env.VUE_APP_APPLICATION_DOWNLOAD_LINK;
    },
        fetchPropertyData() {
            const name = this.$route.params.id.replaceAll('-', ' ').toLocaleLowerCase()
            this.$store.dispatch('property/showProperty',name).then((response) => {
                this.property = response.data
                this.gallery = response.data.property_gallery.sort((a, b) => a.image_position - b.image_position)
                this.loadImages()
                setTimeout(() => {
          const targetDiv = document.querySelector('body');
          targetDiv.scrollIntoView({ behavior: "smooth" });
        }, 400)
            });
        },
        loadImages() {
            const dom = this
            setTimeout(() => {
                dom.$nextTick(() => {
                    $('.main-slider-for').slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: true,
                        fade: true,
                        cssEase: 'linear',
                        asNavFor: '.slider-nav',
                        lazyLoad: 'ondemand',
                        infinite: true,
                    });
                    $('.slider-nav').slick({
                        slidesToShow: 8,
                        slidesToScroll: 1,
                        asNavFor: '.main-slider-for',
                        dots: false,
                        arrows: false,
                        centerMode: false,
                        focusOnSelect: true,
                        infinite: true,
                        lazyLoad: 'ondemand',
                        responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
                    });
  });
            }, 300)
        },
        date(value) {
            return moment(value).format('D-MMMM-YYYY');
        },
        getImage(image) {
            if (image !== null || image !== '') {
                return process.env.VUE_APP_STORAGE_PATH + image
            }
            return image
        },
        handleCheckNowDate(date) {
            const checkDate = moment(date).isSameOrBefore(moment());
            if (checkDate) {
                return 'Now'
            } else {
                return moment(date).format('D-MMMM-YYYY');
            }
        },
    },
   
}
</script>

<style lang="scss" scoped>

</style>