<template>
            <!-- BREADCRUMB -->
            <div class="bg-theme-overlay">
            <section class="section__breadcrumb ">
                <div class="container">
                    <div class="row d-flex justify-content-center">
                        <div class="col-md-12 text-center">
                            <slot name="main-heading" />
                        </div>
                        <div class="col-md-8 text-center">
                           <slot />
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- END BREADCRUMB -->
</template>

<script>
export default {
    setup () {
        

        return {}
    }
}
</script>

<style lang="scss" scoped>

</style>