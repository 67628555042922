<template>
     <AppBanner>
        <h2 class="text-capitalize text-white ">FAQs</h2>
    </AppBanner>
    <section class="faq-section">
        <div class="container">
            <div class="accordion" id="faqs">

              <div class="card accord-card" v-for="(item, index) in faqs" :key="index">
      <div class="card-header">
        <h2 class="mb-0">
          <button
            class="btn btn-link btn-block text-left accord_heading"
            type="button"
            @click="toggleAccordion(index)"
          >
            {{ item.question }}
          </button>
        </h2>
      </div>
      <transition name="slide" mode="out-in">
        <div
          v-if="activeAccordionIndex === index"
          class=""
          :key="index"
        >
          <div class="card-body">
            <div v-html="item.answer"/>
          </div>
        </div>
      </transition>
    </div>
</div>
        </div>
    </section>
</template>

<script>
import AppBanner from '@/layouts/AppBanner.vue';
import LoaderView from  '../assets/images/app-loader.gif'
export default {
    components: {
    AppBanner,
    },
    data() {
        return {
            LoaderView,
            is_loading: false,
            activeAccordionIndex: 0
        }
    },
    computed: {
        faqs() {
            return this.$store.state.faqs.faqs
        },
    },
    mounted() {
        this.fetchFaqsData()
    },
    methods:{
        fetchFaqsData() {
          this.is_loading = true;
          this.$store.dispatch('faqs/allFaqs').then(() => {
                // this.itemsPerPage = response.data.per_page;
                this.is_loading = false;
            })
        },
        toggleAccordion(index) {
          this.activeAccordionIndex = index;
    }
    }
}
</script>

<style scoped>
.faq-section{
    min-height: 50vh;
}
.accord-card{
    background: #fff;
    /* border: none; */
}
.accord_heading{
    font-size: 22px;
    /* border-bottom:1px solid #ccc; */
}


.slide-enter-active, .slide-leave-active {
  transition: height 1s ease-in-out;
  overflow: hidden;
}
.slide-enter, .slide-leave-to {
  height: 0;
}
</style>