<template>
    <!-- Footer  -->
    <footer>
        <div class="wrapper__footer bg-theme-footer">
            <div class="container">
                <div class="row">
                    <!-- ADDRESS -->
                    <div class="col-md-6">
                        <div class="widget__footer">
                            <figure class="text-white">
                                <!-- <img src="../assets/images/logo-blue.png" alt="" class="logo-footer"> -->
                                <h4 class="footer-title">Safe and Sound Real Estate Inc</h4> 
                            </figure>
                            <p>
                                Our homes include an abundance of neighborhood retail, restaurants, office space, and more. With so much convenience right at your doorstep, there's hardly a need to venture further, but the direct access to the Metro, I-495, and I-395 make downtown DC, Old Town Alexandria, and more easily accessible.

                            </p>

                            <!-- <ul class="list-unstyled mb-0 mt-3">
                                <li> <b> <i class="fa fa-map-marker"></i></b><span>214 West Arnold St. New York, NY
                                        10002</span> </li>
                                <li> <b><i class="fa fa-phone-square"></i></b><span>(123) 345-6789</span> </li>
                                <li> <b><i class="fa fa-phone-square"></i></b><span>(+100) 123 456 7890</span> </li>
                                <li> <b><i class="fa fa-headphones"></i></b><span>support@realvilla.demo</span> </li>
                                <li> <b><i class="fa fa-clock-o"></i></b><span>Mon - Sun / 9:00AM - 8:00PM</span> </li>
                            </ul> -->
                        </div>

                    </div>

                    <div class="col-md-6">
                        <div class="widget__footer">
                            <h4 class="footer-title">Address</h4>
                            <ul class="list-unstyled mb-0 mt-3">
                                <li style="display: flex; align-items: center;"> <b> <i class="fa fa-envelope"></i></b><span>PO Box 4146 Merrifield, VA 22116</span> </li>
                                <li style="display: flex; align-items: center;"> <b><i class="fa fa-phone-square"></i></b><span>703-677-5944</span> </li>
                                <!-- <li style="display: flex; align-items: center;"> <b><i class="fa fa-envelope"></i></b><span>safeandsoundrealestate@gmail.com</span> </li> -->
                                <li style="display: flex; align-items: center;"> <b><i class="fa fa-clock-o"></i></b><span>Monday - Sunday / 9:00AM - 5:00PM</span> </li>
                            </ul>
                        </div>

                    </div>
                    <!-- END ADDRESS -->

                    <!-- QUICK LINKS -->
                    <!-- <div class="col-md-4">
                        <div class="widget__footer">
                            <h4 class="footer-title">Quick Links</h4>
                            <div class="link__category-two-column">
                                <ul class="list-unstyled ">
                                    <li class="list-inline-item">
                                        <a href="#">Commercial</a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="#">business</a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="#">House</a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="#">Residential</a>
                                    </li>

                                    <li class="list-inline-item">
                                        <a href="#">Residential Tower</a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="#">Beverly Hills</a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="#">Los angeles</a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="#">The beach</a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="#">Property Listing</a>
                                    </li>

                                    <li class="list-inline-item">
                                        <a href="#">Clasic</a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="#">Modern Home</a>
                                    </li>

                                    <li class="list-inline-item">
                                        <a href="#">Luxury</a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="#">Beach Pasadena</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> -->
                    <!-- END QUICK LINKS -->
                </div>
            </div>
        </div>

        <!-- Footer Bottom -->
        <div class="bg__footer-bottom-v1">
            <div class="container">
                <div class="row flex-column-reverse flex-md-row">
                    <div class="col-md-6">
                        <span>
                            © {{new Date().getFullYear()}} Safe and Sound Real Estate Inc(v0.1)
                        </span>
                    </div>
                    <div class="col-md-6">
                        <ul class="list-inline ">
                            <li class="list-inline-item">
                                <router-link to="/terms-of-service">
                                    Terms of Service
                                </router-link>
                            </li>
                            <li class="list-inline-item">
                                <router-link to="/privacy-policy">
                                    Privacy Policy
                                </router-link>
                            </li>
                            <li class="list-inline-item" style="cursor:pointer">
                                <router-link to="/contact">
                                    contact
                                </router-link>
                            </li>
                            <li class="list-inline-item" style="cursor:pointer">
                                <router-link to="/about">
                                    about
                                </router-link>
                            </li>
                            <li class="list-inline-item">
                                <router-link to="/faqs">
                                    FAQ
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Footer  -->
    </footer>


    <!-- SCROLL TO TOP -->
    <a href="javascript:" id="return-to-top"><i class="fa fa-chevron-up"></i></a>
    <!-- END SCROLL TO TOP -->
</template>

<script>
import '../assets/js/site.js'
    export default {
        
    }
</script>

<style scoped>

</style>