import { axios } from './index'

export default {

    store(data) {
        return axios
          .post('contact-us', data)
          .then(response => Promise.resolve(response.data))
          .catch(error => Promise.reject(error))
      },
}

