<template>
    <AppBanner>
        <template #main-heading>
            <h2 class="text-capitalize text-white ">YOUR HOME THE WAY YOU'VE ALWAYS WANTED IT.</h2>
        </template>
        <h5 class="text-white">When you live here, you get more than a home.
            You get an entire neighborhood where you can live, work, shop, dine, and play.</h5>
        <p class="text-white">
            Our homes include an abundance of neighborhood retail, restaurants, office space, and more.
            With so much convenience right at your doorstep, there's hardly a need to venture further, but the direct access
            to the Metro, I-495, and I-395 make downtown DC, Old Town Alexandria, and more easily accessible.
        </p>
    </AppBanner>
    <div class="container property-container">
        <FeaturedProperties />
    </div>

    <!-- <Testimonials /> -->
</template>

<script>
import AppBanner from '@/layouts/AppBanner.vue';
import FeaturedProperties from '@/components/PropertyList.vue';
// import Testimonials from '../components/Testimonials.vue'
export default {
    components: {
        AppBanner,
        FeaturedProperties,
    }
}
</script>

<style lang="scss" scoped></style>