<template>
    <AppBanner>
        <template #main-heading>
            <h2 class="text-capitalize text-white ">YOUR HOME THE WAY YOU'VE ALWAYS WANTED IT.</h2>
        </template>
     
        <h5 class="text-white">When you live here, you get more than a home.
            You get an entire neighborhood where you can live, work, shop, dine, and play.</h5>
        <p class="text-white">
            Our homes include an abundance of neighborhood retail, restaurants, office space, and more.
            With so much convenience right at your doorstep, there's hardly a need to venture further, but the direct access
            to the Metro, I-495, and I-395 make downtown DC, Old Town Alexandria, and more easily accessible.
        </p>
    </AppBanner>
    <div>
        <!-- ABOUT -->
        <section class="home__about bg-light">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="title__leading">
                            <!-- <h6 class="text-uppercase">trusted By thousands</h6> -->
                            <h2 class="text-capitalize">We Are More Than Just Management</h2>
                            <p>
                                Although we manage properties, our business is people. We work hard to create great homes
                                for people to live, work, play, and stay. We take pride in the long-term relationships we
                                have with our residents and team members.
                            </p>
                            <p>
                                Our client’s residents, guests, and tenants enjoy professional customer service with a
                                personal touch, and our team members understand the unique commitment we have to them, and
                                their careers.

                            </p>
                            <!-- <a href="#" class="btn btn-primary mt-3 text-capitalize"> read more
                            <i class="fa fa-angle-right ml-3 "></i></a> -->
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about__image">
                            <div class="about__image-top">
                                <div class="about__image-top-hover">
                                    <img src="../assets/images/gallery.jpg" alt="" class="img-fluid">
                                </div>

                            </div>
                            <div class="about__image-bottom">
                                <div class="about__image-bottom-hover">
                                    <img src="../assets/images/gallery3.jpg" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- END ABOUT -->

        <!-- OUR TEAM -->
        <section class="bg-light">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 mx-auto">
                        <div class="title__head">
                            <h2 class="text-center text-capitalize text-gray">
                                Our friendly team is here to help you
                            </h2>
                            <!-- <p class="text-center text-capitalize">see our top of the month teams</p> -->
                        </div>
                    </div>
                    <div class="clearfix"></div>
                </div>
                <div class="">
                    <div class="team-members-row-view mt-5">
              
                        <div class="mb-4" v-for="(staff, index) of staffs" :key="index">
                            <div class="wrap-agent">
                                <div class="team-member">
                                    <div class="team-img">
                                        <img alt="team member" class="img-fluid w-100"
                                        src="../assets/images/avatar.jpg" v-if="staff.avatar === null">
                                        <img alt="team member" class="img-fluid w-100"
                                        v-lazy="getImage(staff.avatar)" v-else>
                                    </div>
                                    <div class="team-title">
                                        <h6>
                                            {{staff.first_name}}  {{staff.last_name}}
                                        </h6>
                                        <span>
                                           <span v-if="staff.title !== null"> {{staff.title}} </span>   
                                           <!-- <span v-if="staff.start_date !== null">- Since: {{ getDate(staff.start_date) }}</span> -->
                                        </span>
                                    </div>
                                </div>
                            </div>


                        </div>
                  
                    </div>
                </div>

            </div>
        </section>
        <!-- END TEAM -->
    </div>
</template>

<script>
import AppBanner from '@/layouts/AppBanner.vue';
import moment from 'moment'
export default {
    components: {
        AppBanner,
    },
    data() {
        return {

        }
    },
    computed: {
        staffs() {
            return this.$store.state.about.staffs
        },

    },

    mounted() {
        this.fetchStaffData()
    },

    methods: {
        fetchStaffData() {
            //   this.is_loading = true;
            this.$store.dispatch('about/allStaff', {is_visible_on_website: 1, order_field: 'visible_on_website_position', ranking: 'asc'.toUpperCase()}).then(() => {
                // this.itemsPerPage = response.data.per_page;
                // this.is_loading = false;
            })
        },
        getImage(items) {
      const image = items;
      if (image.length > 0) {
        return process.env.VUE_APP_STORAGE_PATH + image
      }
      return;
    },
    getDate(date) {
        const formatedDate = moment(date).format('D-MMM-YYYY') 
        return formatedDate
    }
    },

}
</script>

<style lang="css" scoped>
.team-img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.team-img img {
    width: 179px !important;
    height: 192px !important;
}

.wrap-agent .team-title {
    border: none !important;
}</style>