import Toastify from 'toastify-js';

function toast(message, messageType = "success"){
    Toastify({
        text: message,
        gravity: "top", // `top` or `bottom`
        position: "center", // `left`, `center` or `right`
        duration: 3000,
        style: {
            background: messageType === 'success' ? "linear-gradient(to right, #00b09b, #96c93d)" : "red",
          }
        }).showToast();   
}

export default toast;