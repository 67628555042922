import { createStore } from "vuex";
// Modules
import property from './property';
import contact from  './contact';
import faqs from "./faqs";
import page_content from "./page_content";
import about from "./about"
export default createStore({
  modules: {
    property,
    contact,
    page_content,
    faqs,
    about
  },
  strict: process.env.DEV,
});
