import PageContentService from '../services/page_content'
import toast from '../toast';


export default {
    namespaced: true,
    state: {
        privacy_policy_contnet: {},
        terms_of_service_content: {},
    },
    mutations:{
        SHOW_PRIVACY_POLICY_CONTENT(state, data) {
            state.privacy_policy_contnet = data
        },
        SHOW_TERMS_OF_SERVICES_CONTENT(state, data) {
            state.terms_of_service_content = data
        }
    },
    actions: {
        // eslint-disable-next-line no-unused-vars
        showPrivacyPolicyContent({ commit }, query) {
            return PageContentService.getPageOfContent(query)
                .then(response => {
                    commit('SHOW_PRIVACY_POLICY_CONTENT', response.data)
                    return Promise.resolve(response)
                })
                .catch(error => {
                    toast(error.response.data.message, 'error')
                    return Promise.reject(error.response.data.message)
                })
        },
        showTermsOfServiceContent({ commit }, query) {
            return PageContentService.getPageOfContent(query)
                .then(response => {
                    commit('SHOW_TERMS_OF_SERVICES_CONTENT', response.data)
                    return Promise.resolve(response)
                })
                .catch(error => {
                    toast(error.response.data.message, 'error')
                    return Promise.reject(error.response.data.message)
                })
        },
    },
}
