<template>
  <div class="col-lg-12">
    <div class="row">
      <div class="col-lg-12">
        <div class="tabs__custom-v2 ">
          <!-- FILTER VERTICAL -->
          <ul class="nav nav-pills myTab mb-5 nav-bar-tab" role="tablist">
            <li class="list-inline-item mr-auto calander-view-box">
              <div class="available-property-box pt-1">
                <span class="avail_heading_text text-capitalize" v-if="thisMonth">Properties Available This
                  Month: </span>
                <span class="avail_heading_text text-capitalize" v-else>Properties Coming Available In 
                  {{ selected_month_year }}: </span>
                <div class="month_date_generator_view">
                  <span class="fa fa-play icon-view icon-left" :class="thisMonth ? 'is_disable_back_button': ''" @click="thisMonth ? '' : goToPreviousMonth()"></span>
                  <button class="btn btn-outline-secondary now-button" @click="fetchNowData">This Month</button>
                  <!-- <select class="available-date-select-input" v-model="selected_month_year" @change="handleCurrentMonthChange">
                    <option class="available-date-select-option" v-for="(month, index) of monthList" :key="index"
                      :value="month">{{ month }}</option>
                  </select> -->
                  <span class="fa fa-play icon-view" @click="goToNextMonth"></span>

         
             
                </div>
                <CustomDatePicker class="" @select-date="handleSelectDate" :value="selected_month_year"/>
              </div>


            </li>
            <li class="nav-item tab-selector-view">
              <ul class="toggle-property-items">

                <li class="nav-item" @click="handleIsShowPropertyList(1)">
              <a class="nav-link" :class="is_show_property === 1? 'active': ''"
                aria-selected="true">
                <span class="fa fa-th-list"></span>
              </a>
            </li>
            <li class="nav-item" @click="handleIsShowPropertyList(2)">
              <a class="nav-link" :class="is_show_property === 2 ? 'active': ''" 
                aria-selected="false">
                <span class="fa fa-th-large"></span></a>
            </li>
              </ul>
            </li>
           
          </ul>

          
          <!-- <VueDatePicker v-model="monthYear" month-picker auto-apply :close-on-auto-apply="true"
                    :format="dateFormat" :input-class="datepickerInputClass" @update:model-value="handleMonthChange">
                  </VueDatePicker> -->
          <div class="loaderView" v-if="!is_loading && properties.data && properties.data.length < 1">
            <img :src="NotFoundIcon" alt="loader" class="not-found-icon" />
            <h3 class="text-capitalize">No Properties Coming Available In {{ selected_month_year }}</h3>
          </div>

          <div class="loaderView" v-if="is_loading">
            <img :src="LoaderView" alt="loader" />
          </div>

          <div class="tab-content" id="myTabContent" v-else>
            <div class="tab-pane fade"  :class="is_show_property === 1 ? 'show active': ''">
              <div class="row">
                <div class="col-lg-12" v-for="property in properties.data" :key="property.id"
                  @click="showDetails(property)">
                  <div class="card__image card__box-v1">
                    <div class="row no-gutters">
                      <div class="col-md-4 col-lg-3 col-xl-4">
                        <div class="card__image__header h-250">
                          <a href="#">
                            <!-- <div class="ribbon text-capitalize" :class="property.status === 'available' ? 'ribbon-primary' : property.status === 'rented'? 'ribbon-success': ''">{{property.status}}</div> -->
                            <img v-lazy="getImage(property.property_gallery)" alt=""
                              class="img-fluid w100 img-transition">
                            <!-- <div class="info"> {{ formatCurrency(property.monthly_rent_wanted)   }}</div> -->
                          </a>
                        </div>
                      </div>
                      <div class="col-md-4 col-lg-6 col-xl-5 my-auto">
                        <div class="card__image__body">
                          <p class="mb-3">When Available: <b
                              class="ml-2">{{ handleCheckNowDate(property.available_property_date) }}</b></p>
                          <!-- <span class="badge badge-success text-capitalize mb-2">{{property.property_name}}</span> -->
                          <h6>
                            <a href="#">{{ property.property_name }}</a>
                          </h6>
                          <div class="card__image__body-desc">
                            <!-- <p class="text-capitalize">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, alias!
                
                                    </p> -->
                            <p class="text-capitalize">
                              <i class="fa fa-map-marker"></i>
                              {{ property.street_address }} <br />{{ property.city }} {{ property.state }} {{
                                property.zip_code }}
                            </p>
                          </div>

                          <ul class="list-inline card__content">
                            <li class="list-inline-item">
                              <span>
                                bed <br>
                                <!-- <i class="fa fa-bed"></i>  -->
                                {{ property.bedroom }}
                              </span>
                            </li>
                            <li class="list-inline-item">

                              <span>
                                bath <br>
                                <!-- <i class="fa fa-bath"></i>  -->
                                {{ parseInt(property.bath_half) > 0 ? `${parseInt(property.bath_full)} /
                                                                ${parseInt(property.bath_half)}` : parseInt(property.bath_full) }}
                              </span>
                            </li>

                            <!-- <li class="list-inline-item">
                              <span>
                                year build <br>
                                <i class="fa fa-home"></i> {{ property.year_built }}
                              </span>
                            </li> -->
                            <li class="list-inline-item">
                              <span>
                                Size <br>
                                <!-- <i class="fa fa-map"></i>  -->
                                {{ property.size }} <span style="text-transform: lowercase !important;">sf</span>
                              </span>
                            </li>

                            <li class="list-inline-item">
                              <span>
                                Rent <br>
                                {{ formatCurrency(property.monthly_rent_wanted) }}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <!-- <div class="col-md-4 col-lg-3 col-xl-3 my-auto card__image__footer-first">
                        <div class="card__image__footer">
                          <figure>
                            <img src="../assets/images/80x80.jpg" alt="" class="img-fluid rounded-circle">
                          </figure>
                          <ul class="list-inline my-auto">
                            <li class="list-inline-item name">
                              <a href="#">
                                Amber Williams
                              </a>

                            </li>


                          </ul>
                          <ul class="list-inline  my-auto ml-auto price">
                            <li class="list-inline-item ">

                              <h6>${{ property.monthly_rent_wanted  }}</h6>
                            </li>

                          </ul>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>

              </div>
              <div class="clearfix"></div>
            </div>
            <div class="tab-pane fade " :class="is_show_property === 2 ? 'show active': ''" >



              <div class="row">
                <div class="col-md-4 col-lg-4" v-for="property in properties.data" :key="property.id"
                  @click="showDetails(property)">
                  <div class="card__image card__box-v1">
                    <div class="card__image-header h-250">
                      <!-- <div class="ribbon text-capitalize" :class="property.status === 'available' ? 'ribbon-primary' : property.status === 'rented'? 'ribbon-success': ''">{{property.status}}</div> -->
                      <img v-lazy="getImage(property.property_gallery)" alt="" class="img-fluid w100 img-transition">
                      <!-- <div class="info"> </div> -->
                    </div>
                    <div class="card__image-body">
                      <!-- <span class="badge badge-success text-capitalize mb-2">{{ property.property_type}}</span> -->
                      <p class="mb-3">When Available: <b
                          class="ml-2">{{ handleCheckNowDate(property.available_property_date) }}</b></p>
                      <h6 class="text-capitalize">
                        {{ property.property_name }}
                      </h6>

                      <p class="text-capitalize">
                        <i class="fa fa-map-marker"></i>
                        {{ property.street_address }} <br />{{ property.city }} {{ property.state }} {{ property.zip_code
                        }}
                      </p>
                      <ul class="list-inline card__content">
                        <li class="list-inline-item">
                          <span>
                            bed <br>
                            <!-- <i class="fa fa-bed"></i>  -->
                            {{ property.bedroom > 0 ? property.bedroom : "Studio" }}
                          </span>
                        </li>
                        <li class="list-inline-item">

                          <span>
                            bath <br>
                            <!-- <i class="fa fa-bath"></i>  -->
                            {{ parseInt(property.bath_half) > 0 ? `${parseInt(property.bath_full)} /
                                                        ${parseInt(property.bath_half)}` : parseInt(property.bath_full) }}

                          </span>
                        </li>

                        <li class="list-inline-item">
                          <!-- <span>
                                year build <br>
                                <i class="fa fa-home"></i> {{ property.year_built }}
                              </span> -->
                        </li>
                        <li class="list-inline-item">
                          <span>
                            Size <br>
                            <!-- <i class="fa fa-map"></i>  -->
                            {{ property.size }} <span style="text-transform: lowercase !important;">sf</span>
                          </span>
                        </li>

                        <li class="list-inline-item">
                          <span>
                            Rent <br>
                            {{ formatCurrency(property.monthly_rent_wanted) }}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <!-- <div class="card__image-footer">
                      <figure>
                        <img src="../assets/images/amber-williams-17416828.jpg" alt="" class="img-fluid rounded-circle">
                      </figure>
                      <ul class="list-inline my-auto">
                        <li class="list-inline-item ">
                          <a href="#">
                            Amber Williams
                          </a>

                        </li>

                      </ul>
                      <ul class="list-inline my-auto">
                        <li class="list-inline-item">

                          <h6>${{ property.monthly_rent_wanted  }}</h6>
                        </li>

                      </ul>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="cleafix"></div>
            </div>
          </div>
          <!-- END FILTER VERTICAL -->

          <div class="text-center mt-5">
            <vue-awesome-paginate :total-items="totalPages" :items-per-page="itemsPerPage" :max-pages-shown="5"
              v-model="currentPage" :on-click="handlePageChange" />
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { formatCurrencyMixin } from '../mixins/formatCurrencyMixin'
import moment from 'moment';
import CustomDatePicker from './CustomDatePicker';
import LoaderView from '../assets/images/app-loader.gif'
import NotFoundIcon from '../assets/images/no-results.png'
export default {
  name: 'Property-List',
  props: {
    msg: String
  },
  components: {
    CustomDatePicker
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 15,
      totalPages: 1,
      LoaderView,
      NotFoundIcon,
      selected_month_year: moment().format("MMMM-YYYY"),
      selected_current_year: moment().format("MMMM-YYYY"),
      currentDate: new Date(),
      monthYear: moment().format("MMMM-YYYY"),
      now_mount: moment().format("MMMM-YYYY"),
      currentMonth: null,
      monthList: [],
      is_nowDate: false,
      enable_back_button: false,
      is_loading: false,
      dateFormat: "MMMM-yyyy",
      currentDateString: '',
      is_show_property: 2,
    };
  },
  
  mixins: [formatCurrencyMixin],
  computed: {
    thisMonth(){
      return this.selected_month_year == moment().format("MMMM-YYYY")
    },
    properties() {
      return this.$store.state.property.properties
    },
    datepickerInputClass() {
      return 'disable-previous-months';
    },
  },
  mounted() {
    this.fetchPropertyData()
    this.generateMonthList();
  },
  watch: {
    // selected_month_year(value) {
    //   const currentMonthYear = moment().format("MMMM-YYYY");
    //   const isSameOrBefore = moment(value, "MMMM-YYYY").isSame(currentMonthYear, "MMMM-YYYY");

    //   if (isSameOrBefore) {
    //     this.is_nowDate = false
    //   } else {
    //     this.is_nowDate = true
    //   }  
    // },
    monthList: {
      handler(newData, oldData) {
        const currentMonthYear = moment().format("MMMM-YYYY");
        const includeCurrentDate = newData.includes(currentMonthYear)
      if(includeCurrentDate){
           this.enable_back_button = false
        }else{
          this.enable_back_button = true
        }
      },
      deep: true,
    }
  },
  methods: {
    handleIsShowPropertyList(id) {
      this.is_show_property = id
    },
    fetchPropertyData() {
      this.is_loading = true;
      this.$store.dispatch('property/allProperty', { paginate: true, availability_date: this.selected_month_year }).then((response) => {
        // this.itemsPerPage = response.data.per_page;
        this.is_loading = false;
        this.totalPages = response.data.total;
      })
    },

    fetchNowData() {
      this.is_loading = true;
      this.is_nowDate = false
      const formatDate = moment().format("MMMM-YYYY")
      this.selected_month_year = formatDate
      this.enable_back_button = false
      this.currentDate = new Date()
      this.$store.dispatch('property/allProperty', { paginate: true, availability_date: formatDate }).then((response) => {
        // this.itemsPerPage = response.data.per_page;
        this.is_loading = false;
        this.totalPages = response.data.total;
      })
    },
    handleCheckNowDate(date) {

      const checkDate = moment(date).isSameOrBefore(moment());

      if (checkDate) {
        return 'Now'
      } else {
        return moment(date).format('D-MMMM-YYYY');
      }
    },
    handlePageChange(page) {
      this.is_loading = true;
      this.$store.dispatch('property/allProperty', { paginate: true, page, availability_date: this.selected_month_year }).then((response) => {
        this.is_loading = false;
        this.totalPages = response.data.total;
        setTimeout(() => {
          const targetDiv = document.querySelector('.tabs__custom-v2 ');
          targetDiv.scrollIntoView({ behavior: "smooth" });
        }, 300)

      })
      // Fetch new data or perform any other action based on the selected page
    },

    handleMonthChange(date) {
      const { year, month } = date
      const dateString = new Date(year, month)

      const formatDate = moment(dateString).format("MMMM-YYYY")
      this.selected_month_year = formatDate
      this.currentMonth = moment(dateString).format("MMMM")
      this.currentDateString = dateString
      this.fetchPropertyData()
    },
    handleSelectDate(date) {
      this.selected_month_year = date
        this.fetchPropertyData()
    },
    handleCurrentMonthChange(e) {
      const date = e.target.value;
      this.selected_month_year = date
      this.fetchPropertyData()
    },

    getImage(items) {
      const image = items;
      if (image.length > 0) {
        return process.env.VUE_APP_STORAGE_PATH + image[0].file_name
      }
      return;
    },
    showDetails(item) {

      // localStorage.setItem('safe_details_id', item.id)
      this.$store.dispatch('property/showProperty', item.property_name)
      const slug = item.property_name.replaceAll(' ', '-').toLocaleLowerCase()
      this.$router.push(`/property/${slug}`);
      // window.location.href = `/property/${slug}`
    },
    date(value) {
      return moment(value).format('D-MMMM-YYYY');
    },

    generateMonthList() {
      const startMonth = this.currentDate.getMonth();
      const startYear = this.currentDate.getFullYear();

      this.monthList = [];

      let currentMonth = startMonth;
      let currentYear = startYear;

      for (let i = 0; i < 13; i++) {
        const month = new Date(currentYear, currentMonth);
        this.monthList.push(moment(month).format("MMMM-YYYY"));

        if (currentMonth === 11) {
          currentMonth = 0;
          currentYear++;
        } else {
          currentMonth++;
        }
      }
    },

    goToPreviousMonth() {
      // this.currentDate = new Date(
      //   this.currentDate.getFullYear(),
      //   this.currentDate.getMonth() - 1,
      //   1
      // );
      
      // const nowMonth = moment().format("MMMM-YYYY");
      // const currentDateCreated = moment(this.currentDate).format("MMMM-YYYY")
      // if(nowMonth === currentDateCreated){
      //   this.is_nowDate = false
      // }else{
      //   this.is_nowDate = true
      // }
      // this.selected_month_year = currentDateCreated
      this.selected_month_year = moment(this.selected_month_year,"MMMM-YYYY").subtract(1,'M').format("MMMM-YYYY")
      this.generateMonthList();
      this.fetchPropertyData()
    },
    goToNextMonth() {
      // this.currentDate = new Date(
      //   this.currentDate.getFullYear(),
      //   this.currentDate.getMonth() + 1,
      //   1
      // );
      
      // const nowMonth = moment().format("MMMM-YYYY");
      // const currentDateCreated = moment(this.currentDate).format("MMMM-YYYY")
      // if(nowMonth === currentDateCreated){
      //   this.is_nowDate = false
      // }else{
      //   this.is_nowDate = true
      // }
      // this.selected_month_year = currentDateCreated
      this.selected_month_year = moment(this.selected_month_year,"MMMM-YYYY").add(1,'M').format("MMMM-YYYY")
      this.generateMonthList();
      this.fetchPropertyData()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.ribbon-primary {
  background: #3c9e65 !important;
}

.ribbon-success {
  background: #f28500 !important;
}
</style>
