<template>
        <!-- <section class="section__breadcrumb ">
            <div class="container">
                <div class="row d-flex justify-content-center">
                    <div class="col-md-8 text-center">
                        <h2 class="text-capitalize text-white ">YOUR HOME THE WAY YOU'VE ALWAYS WANTED IT.</h2>
                        <h5 class="text-white">When you live here, you get more than a home.
You get an entire neighborhood where you can live, work, shop, dine, and play.</h5>
                        <p class="text-white">
                            Our homes include an abundance of neighborhood retail, restaurants, office space, and more.
With so much convenience right at your doorstep, there's hardly a need to venture further, but the direct access to the Metro, I-495, and I-395 make downtown DC, Old Town Alexandria, and more easily accessible.
                        </p>
                    </div>
                </div>
            </div>
        </section> -->
            <!-- HEADER -->
    <header>
        <!-- NAVBAR TOP -->
<div class="topbar">
    <div class="container">
        <div class="top-view-item">
            <div class="topbar-left">
                    <div class="topbar-text">
                        <span>NEED HELP?</span> <a href="#" class="text-white"><i class="fa fa-phone"></i></a>
                        <span class="text-white"> 703-677-5944</span>
                    </div>
                </div>

                <div class="list-unstyled topbar-right">
                    <ul class="topbar-link">
                        <li><span title="" @click="handleOpenAppLogin" style="cursor: pointer; color: white;">Login</span></li>
                    </ul>
                </div>
        </div>
    </div>
 
</div>
<!-- END NAVBAR TOP -->
        <!-- NAVBAR -->
        <nav class="navbar navbar-hover navbar-expand-lg navbar-soft">
    <div class="container">
        <a class="navbar-brand" href="#" @click="$router.push('/').catch(() => {})">
            Safe and Sound Real Estate Inc
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#main_nav99">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="main_nav99">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <router-link class="nav-link" to="/"> Home </router-link>
                </li>
                <li class="nav-item"><router-link class="nav-link" to="/about"> About Us </router-link></li>
                <!-- <li class="nav-item" @click="$router.push('/properties').catch(() => {})"><a class="nav-link" href="#"> Property List </a></li> -->
                <li class="nav-item" ><router-link class="nav-link"  to="/contact"> Contact </router-link></li>

                <li class="nav-item"><a class="nav-link" :href="downloadLink()" target="_blank"> Download Rental Application </a></li>
            </ul>
      

            <!-- Search bar.// -->
            <!-- Search content bar.// -->
            <div class="top-search navigation-shadow">
                <div class="container">
                    <div class="input-group ">
                        <form action="#">

                            <div class="row no-gutters mt-3">
                                <div class="col">
                                    <input class="form-control border-secondary border-right-0 rounded-0" type="search"
                                        value="" placeholder="Search " id="example-search-input4">
                                </div>
                                <div class="col-auto">
                                    <a class="btn btn-outline-secondary border-left-0 rounded-0 rounded-right"
                                        href="/search-result.html">
                                        <i class="fa fa-search"></i>
                                    </a>
                                </div>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <!-- Search content bar.// -->
        </div> <!-- navbar-collapse.// -->
    </div>
</nav>
        <!-- END NAVBAR -->
    </header>
</template>

<script setup>
    import '../assets/css/styles.css?fd365619e86ad9137a29'
  
    function handleOpenAppLogin() {
        window.open(`${process.env.VUE_APP_DASHBOARD_LINK}`, '_blank')
    }
    function downloadLink(){
        return process.env.VUE_APP_APPLICATION_DOWNLOAD_LINK;
    }
    

</script>

<style scoped>

</style>
