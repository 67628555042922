<template>
  <AppHeader />
  <router-view></router-view>
  <AppFooter />

</template>

<script>
import AppHeader from './layouts/AppHeader.vue'
import AppFooter from './layouts/AppFooter.vue'


export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
}
}
</script>

<style>

</style>
