import ContactService from '../services/contact'
import toast from '../toast';
import Loader from '../loader';

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    contactUs({ commit, actions }, data) {
        Loader.open()
        return ContactService.store(data)
            .then(response => {
                Loader.close()
                toast("Thank you for contacting us!, we will get back to you shortly")
                return Promise.resolve(response)
            })
            .catch(error => {
                Loader.close()
                toast(error.response.data.message, 'error')
                return Promise.reject(error.response.data.message)
            })
    },
  },
}
