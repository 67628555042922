import { axios } from './index'

export default {
    index(params) {
        return axios
          .get('faqs', {
            params,
          })
          .then(response => Promise.resolve(response))
          .catch(error => Promise.reject(error))
      },
}

