<template>
    <AppBanner>
       <h2 class="text-capitalize text-white ">Terms Of Service</h2>
   </AppBanner>
   <section>
       <div class="container">
           <div class="card">
               <div class="card_body p-4">
                <div class="loaderView" v-if="is_loading">
                <img :src="LoaderView" alt="loader" />
            </div>
                   <div v-html="page_content.content" v-else />
               </div>
           </div>
       </div>
   </section>
</template>

<script>
import AppBanner from '@/layouts/AppBanner.vue';
import LoaderView from  '../assets/images/app-loader.gif'
export default {
   components: {
   AppBanner,
   },
   data() {
       return {
           LoaderView,
           is_loading: false,
       }
   },
   computed: {
       page_content() {
           return this.$store.state.page_content.terms_of_service_content
       },
   },
   mounted() {
       this.fetchPageContentData()
   },
   methods:{
       fetchPageContentData() {
         this.is_loading = true;
         this.$store.dispatch('page_content/showTermsOfServiceContent', { name: 'terms-of-service' }).then(() => {
               // this.itemsPerPage = response.data.per_page;
               this.is_loading = false;
           })
       },
   }
}
</script>

<style lang="scss" scoped>

</style>