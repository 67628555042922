import FAQsService from '../services/faqs'
import toast from '../toast';

export default {
    namespaced: true,
    state: {
        faqs: [],
    },
    mutations: {
        GET_FAQS(state, data) {
            state.faqs = data
        },
    },
        actions: {
            // eslint-disable-next-line no-unused-vars
            allFaqs({ commit }, query) {
                return FAQsService.index(query)
                    .then(response => {
                        commit('GET_FAQS', response.data)
                        return Promise.resolve(response)
                    })
                    .catch(error => {
                        toast(error.response.data.message, 'error')
                        return Promise.reject(error.response.data.message)
                    })
            }
        }
}
