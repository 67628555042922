<template>
    <div class="custome_date_picker_box" @click="handleShowCalander" ref="elementToClickOutside">
        <div class="icon_view_box">
            <span class="material-symbols-outlined calander_view_icon">
                calendar_today
            </span>

            <span>{{value !== undefined ? value : active_month}}</span>
        </div>



        <span class="material-symbols-outlined calander_close_icon">
            close
        </span>

        <div class="custome_calander_display_box" v-if="is_calander_show">
            <div class="custome_calander_arrow_box"></div>
            <div class="custome_calander_year_month_view">
                <div class="active-year-view">
                    <span class="fa fa-play icon-view icon-left" @click.stop="previousYear"></span>
                    <div class="year_view" @click.stop="handleShowYear">{{active_year}}</div>
                    <span class="fa fa-play icon-view" @click.stop="nextYear"></span>
                </div>
                
                <div class="month_view" v-if="is_year_enable">
                    <div class="column " v-for="(year, index) in years" :key="index" @click.stop="handleSelectYear(year)">{{ year }}</div>
                </div>
                <div class="month_view" v-else>
                    <div class="column " :class="handleDisableMonths(month) ?  'is_disable_month_button': ''" v-for="(month, index) in months" :key="index" @click.stop="handleDisableMonths(month) ? '' : handleSelectMonth(month)">{{ month }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            binding.value();
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      }
    }
  },
    emits: ['selectDate'],
    props: ['value'],
    data() {
        return {
            months: [
                "Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ],
            years: [],
            is_year_enable: false,
            active_year: new Date().getFullYear(),
            active_month: moment().format('MMMM-YYYY'),
            is_calander_show: false,
        }
    },
    watch: {
        active_month() {
            this.is_calander_show = false
        }
    },
    mounted() {
        this.generateYears()
        document.addEventListener('click', this.handleClickOutside);
    },
    methods: {
        generateYears() {
            const currentYear = new Date().getFullYear();
            const years = [];

            for (let i = 0; i < 12; i++) {
                years.push(currentYear + i);
            }

            this.years = years;
        },
        handleShowYear() {
            this.is_year_enable = true
        },
        handleSelectYear(year) {
            this.active_year = year
            this.is_year_enable = false
        },
        
        hideCalander(){
            this.is_calander_show = false
        },
        handleClickOutside(event) {
      const element = this.$refs.elementToClickOutside;
      if (element && !element.contains(event.target)) {
        this.is_calander_show = false
      }
    },
        handleSelectMonth(month) {
            const monthYearString = `${month}/${this.active_year}`; // Replace this with your month year string
            const parsedDate = moment(monthYearString, "MMM/YYYY");
            const formattedDate = parsedDate.format("MMMM-YYYY");
            this.$emit('selectDate', formattedDate);
            this.active_month = formattedDate
            this.is_calander_show = false
        },
        handleShowCalander() {
            this.is_calander_show = !this.is_calander_show
            this.is_year_enable = false
        },
        handleDisableMonths(month) {
            const monthYearString = `${month}/${this.active_year}`;
            const parsedDate = moment(monthYearString, "MMM/YYYY");
            const currentMonthYear = moment().format("MMMM-YYYY");
            const isBefore = moment(parsedDate, "MMMM-YYYY").isBefore(currentMonthYear, "MMMM-YYYY");

            return isBefore;
        },
        nextYear() {
      this.active_year = moment(this.active_year, 'YYYY')
        .add(1, 'year')
        .format('YYYY');
    },
    previousYear() {
      this.active_year = moment(this.active_year, 'YYYY')
        .subtract(1, 'year')
        .format('YYYY');
    },
    }
}
</script>

<style lang="scss" scoped></style>