import { axios } from './index'

export default {
  getPageOfContent(params) {
    return axios
      .get('get-page-content', {
        params,
      })
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error))
  },

}

