import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Properties from '../views/Properties.vue';
import PropertyDetails from '../views/PropertyDetails.vue';
import About from '../views/About.vue';
import Contact from '../views/Contact.vue';
import FAQs from '../views/FAQs.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import TermsOfService from '../views/TermsOfService.vue';
const routes = [
  { path: '/', component: Home },
  { path: '/properties', component: Properties },
  { path: '/property/:id', component: PropertyDetails },
  { path: '/about', component: About },
  { path: '/contact', component: Contact },
  { path: '/faqs', component: FAQs },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/terms-of-service', component: TermsOfService },
  // Additional routes...
];

const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  export default router