export default {
  production: {
    echoURL: 'https://api.safeandsoundrealestate.com/api/v1/',
    baseURL: 'https://api.safeandsoundrealestate.com/api/v1/',
    mainURL: 'https://api.safeandsoundrealestate.com',
    socketURL: 'wss://api.safeandsoundrealestate.com:6001'

    // echoURL: 'https://api.sweetrentalsamerica.com/api/v1/',
    // baseURL: 'https://api.sweetrentalsamerica.com/api/v1/',
    // mainURL: 'https://api.sweetrentalsamerica.com',
    // socketURL: 'wss://api.sweetrentalsamerica.com:6001',
  },

  development: {
    // echoURL: 'https://api.sweetrentalsamerica.com/api/v1/',
    // baseURL: 'https://api.sweetrentalsamerica.com/api/v1/',
    // mainURL: 'https://api.sweetrentalsamerica.com',
    // socketURL: 'wss://api.sweetrentalsamerica.com:6001'
    echoURL: 'http://127.0.0.1:8002/api/v1/',
    baseURL: 'http://127.0.0.1:8002/api/v1/',
    mainURL: 'http://127.0.0.1:8002',
    socketURL: 'ws://127.0.0.1:6001'
  },
}
