import $ from 'jquery';

$(function () {
    $("#return-to-top").on('click', function () {
        // When arrow is clicked
        $("body,html").animate(
            {
                scrollTop: 0 // Scroll to top of body
            },
            500
        );
    });
})

$(function () {

    $(window).on('scroll', function () {
        if ($(this).scrollTop() >= 50) {
            // If page is scrolled more than 50px
            $("#return-to-top").fadeIn(200); // Fade in the arrow
        } else {
            $("#return-to-top").fadeOut(200); // Else fade out the arrow
        } 
    });
})


$(function() {

    $('.navbar-toggler').on('click', function () {
        var navbarCollapse = $('#main_nav99');
        if (navbarCollapse.hasClass('show')) {
          navbarCollapse.slideUp(500); // Slide up with a duration of 300 milliseconds
          navbarCollapse.removeClass('show');
        } else {
          navbarCollapse.slideDown(500); // Slide down with a duration of 300 milliseconds
          navbarCollapse.addClass('show');
        }
      });
})

// $(function() {
//     $('.accord_heading').on('click', function () {
//         var accordionCard = $(this).closest('.accord-card');
//         var accordionCollapse = accordionCard.find('.collapse');
        
//         // Toggle the collapsed class on the accordion card
//         accordionCard.toggleClass('collapsed');
        
//         // Toggle the collapse class on the accordion collapse
//         accordionCollapse.slideToggle();
//       });
// })


	// ------------------------------------------------------------------------------ //
	// SLIDER IMAGE WITH THUMB
	// ------------------------------------------------------------------------------ //


