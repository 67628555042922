<template>
    <div id="map-container"></div>
</template>
  
<script>
import L from 'leaflet';

export default {
    mounted() {
        const map = L.map('map-container').setView([37.926868, -78.024902], 14);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap contributors'
        }).addTo(map);

        map.setZoom(14);
        // Create a custom icon for the marker
        const customIcon = L.icon({
            iconUrl: require('@/assets/images/location.png'), // Adjust the path as needed
            // shadowUrl: require('@/assets/images/location.png'), // Adjust the path as needed
            iconSize: [42, 45],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
        });

        // Add a marker using the custom icon
        L.marker([37.926868, -78.024902], { icon: customIcon }).addTo(map);
        // marker.bindPopup('This is a marker popup.');

        this.$data.map = map;
    },
    watch: {
    '$store.state.property.property': {
      handler(oldValue, newValue) {
        if (oldValue.property_name !== undefined) {
          if (this.map) {
            this.map.remove();
          }

          this.map = L.map('map-container').setView(
            [oldValue.latitude, oldValue.longitude],
            14
          );
          L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '© OpenStreetMap contributors'
        }).addTo(this.map);
        
          // Create a custom icon for the marker
          const customIcon = L.icon({
            iconUrl: require('@/assets/images/location.png'), // Adjust the path as needed
            iconSize: [42, 45],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
          });

          // Add a marker using the custom icon
          this.marker = L.marker([oldValue.latitude, oldValue.longitude], {
            icon: customIcon
          }).addTo(this.map);
        //   this.marker.bindPopup(oldValue.property_name);
        }
      },
      deep: true
    }
  },
    beforeUnmount() {
        if (this.$data.map) {
            this.$data.map.remove();
        }
    },
    data() {
        return {
            map: null
        };
    }
};
</script>
  
<style>
#map-container {
    width: 100%;
    height: 400px;
}
</style>
  