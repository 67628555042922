import Axios from 'axios'
import Config from './config'

export const axios = Axios.create({
  baseURL: Config[process.env.NODE_ENV].baseURL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, x-client-key, x-client-token, x-client-secret, Authorization',
    Accpet: 'application-json',
  },
})

Axios.defaults.withCredentials = false

export const mainAxios = Axios.create({
  baseURL: Config[process.env.NODE_ENV].mainURL,
})
