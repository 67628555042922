import PropertyService from '../services/property'

export default {
  namespaced: true,
  state: {
    properties: [],
    property: {},
  },
  getters: {
    getProperties(state) {
      return state.properties
    },
  },
  mutations: {
    GET_PROPERTIES(state, data) {
      state.properties = data
    },
    SHOW_PROPERTY(state, data){
        state.property = data
    },
    EMPTY_PROPERTY(state) {
      state.properties = []
    }
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    allProperty({ commit, actions }, query) {
      return PropertyService.index(query)
        .then(response => {
          commit('GET_PROPERTIES', response.data)
          return Promise.resolve(response)
        })
        .catch(error => Promise.reject(error.response.data.message))
    },
    showProperty({ commit }, property_id) {
      // const propertyId = JSON.parse(localStorage.getItem('safe_details_id')); 
      // let id = property_id
      // if(propertyId !== undefined){
      //     id = propertyId
      // }
      return PropertyService.show(property_id)
      .then(response => {
        commit('SHOW_PROPERTY', response.data)
        return Promise.resolve(response)
      })
      .catch(error => Promise.reject(error.response.data.message))
    },
    setSingleProperty({ commit }, data) { 
        return commit('SHOW_PROPERTY', data)
    },
    emptyProperty({ commit }){
      commit('EMPTY_PROPERTY')
    }
  },
}
