import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import the package
import VueAwesomePaginate from "vue-awesome-paginate";
import VueLazyload from 'vue-lazyload'

// import the necessary css file
import "vue-awesome-paginate/dist/style.css";
import "toastify-js/src/toastify.css"
import 'slick-carousel/slick/slick.css'; // Import Slick Slider CSS
import 'slick-carousel/slick/slick-theme.css'; // Import Slick Slider Theme CSS
import 'slick-carousel/slick/slick'; // Import Slick Slider JS

import loadimage from './assets/images/app-loader.gif'
const app = createApp(App);
app.use(VueLazyload, {
    preLoad: 1.3,
    // error: errorimage,
    loading: loadimage, // Use the URL of the loading image here
    attempt: 1,
    filter: {
      progressive(listener) {
        const isWasabiS3 = /s3\.wasabisys\.com/;
        if (isWasabiS3.test(listener.src)) {
          listener.el.setAttribute('lazy-progressive', 'true');
          // Don't modify listener.loading here
        }
      },
      webp(listener, options) {
        if (!options.supportWebp) return;
        const isWasabiS3 = /s3\.wasabisys\.com/;
        if (isWasabiS3.test(listener.src)) {
          listener.src += '?format=webp';
        }
      }
    }
  });
app.use(router);
app.use(store)
app.use(VueAwesomePaginate)
app.mount('#app');
